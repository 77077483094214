import React from "react"
import Layout from "../components/Layout"
import ContactUsComponent from "../components/ContactUs"
import SEO from "../components/seo"

export default function ContactUs() {
  return (
    <Layout headerType="index">
      <SEO title="Compulease · Contact Us" />
      <ContactUsComponent sector="Unknown" />
      {/* Customer sector unknown because the homepage is relevant to both Business and Education sectors */}
    </Layout>
  )
}
